
import { MutationTree } from "vuex";
import { ImagingState } from "@/interfaces/customs/IImaging";

const mutation: MutationTree<ImagingState> = {
  setDataPatient(state: ImagingState, data) {
      state.dataPatient = data;
  },

  setDataImaging(state: ImagingState, data) {
    state.dataImaging = data;
  },

  setDataImagingAddDocuments(state: ImagingState, data) {
    state.dataImaging.documents = data;
  },

  setDataImagingDeletedDocuments(state: ImagingState, data) {
    state.dataImaging.deleted_documents = data;
  },

  setDataMovement(state: ImagingState, data){
    state.dataMovement = data;
  }
};

export default mutation;
