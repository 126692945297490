import { useStore } from "vuex";
import { StateInterface } from '@/store/index';
import { computed } from "vue";
import { DataPatient, DataImaging, DataMovement } from "@/interfaces/customs/IImaging";

export const useImaging = () => {

    const store = useStore<StateInterface>();
    
    return {
        storeDataImaging: computed( () => store.state.imaging.dataImaging),
        storeDataPatient: computed( () => store.state.imaging.dataPatient),

        // Actions
        isGetListImaging: (data:any) => store.dispatch('imaging/isGetListImaging', data),
        isGetProceduresSearch: (data:any) => store.dispatch('imaging/isGetProceduresSearch', data),
        loadPatientByDocument: (data:any) => store.dispatch('imaging/loadPatientByDocument', data),
        isGet: (data:any) => store.dispatch('imaging/getImaging', data),
        isCreate: (data:any) => store.dispatch('imaging/createImaging', data),
        isEdit: (data:any) => store.dispatch('imaging/editImaging', data),
        isDownloadImaging: (data:any) => store.dispatch('imaging/downloadImaging', data),
        isSendEmailImaging: (data:any) => store.dispatch('imaging/sendEmailImaging', data),

        //Movements
        isCreateMovement: (data:any) => store.dispatch('imaging/createMovement', data),
        isGetMovements: (data:any) => store.dispatch('imaging/getMovements', data),
        isDeleteMovement: (data:any) => store.dispatch('imaging/deleteMovement', data),

        //Mutations
        isSetDataPatient: (data:DataPatient) => store.commit('imaging/setDataPatient', data),
        isSetDataImaging: (data:DataImaging) => store.commit('imaging/setDataImaging', data), 
        isSetDataImagingAddDocuments: (data:DataImaging) => store.commit('imaging/setDataImagingAddDocuments', data),
        isSetDataImagingDeletedDocuments: (data:DataImaging) => store.commit('imaging/setDataImagingDeletedDocuments', data),
        isSetDataMovement: (data:DataMovement) => store.commit('imaging/isSetDataMovement', data),
    }
}