import { Module } from 'vuex';
import { StateInterface } from '../index';

import state from './state';
import actions from './actions';
import mutations from './mutations';
import { ImagingState } from "@/interfaces/customs/IImaging";


const imagingModule: Module<ImagingState, StateInterface> = {
    namespaced: true,
    actions,
    mutations,
    state
}


export default imagingModule;