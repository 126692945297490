import { ActionTree } from 'vuex';
import { StateInterface } from '../index';
import { ImagingApi, ProceduresSearchApi, PatientsApi, DocumentsApi } from '@/apis/imagingApi';

const actions: ActionTree<any, StateInterface> = {

    async isGetListImaging( { }, data ) {

        const { token, params } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            params
        };
        try{
            const { data } = await ImagingApi.get('', config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async isGetProceduresSearch( { }, data ) {
        const { token, params } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            },
            params
        };
        try{
            const { data } = await ProceduresSearchApi.get('', config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async loadPatientByDocument({ }, data) {

        const { document, token } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };
        try {
          const { data } = await PatientsApi.get(`/by-document/${document}`, config);
          return data;
        } catch (error) {
          return errorExeption(error);
        }
      },

      async createImaging( { }, data ) {

        const { token, formData } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
            }
        };

        try{
            const { data } = await ImagingApi.post('', formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async getImaging( { }, data:any ) {
        const { token, id } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await ImagingApi.get(`/${id}`, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async editImaging( { }, data ) {

        const { token, formData, id } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
            }
        };

        try{
            const { data } = await ImagingApi.post(`/${id}`, formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async downloadImaging( { }, data ) {

        const { token, id } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await DocumentsApi.post(`/${id}/pdf`, undefined, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async sendEmailImaging( { }, data ) {

        const { token, id } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await ImagingApi.post(`/${id}/email`, undefined, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async createMovement( { }, data ) {

        const { token, formData, id } = data;

        const config = {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
            }
        };

        try{
            const { data } = await ImagingApi.post(`/${id}/movements`, formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async getMovements( { }, data:any ) {
        const { token, id } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`
            }
        };

        try{
            const { data } = await ImagingApi.get(`/${id}/movements`, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

    async deleteMovement( { }, data:any ) {
        const { token, formData, id } = data;
        const config = {
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
            }
        };

        try{
            const { data } = await ImagingApi.post(`/movements/${id}`, formData, config);
            return data;
        }catch(error:any){
            return errorExeption(error); 
        }
    },

}

function errorExeption(error: any) {
    
    //No hay conexion
    if(error.message == 'Network Error'){
        const success = false;
        const { message } = error;
        return { success, message }; 
    }            
    const { data } = error.response;
    const { success, message } = data; 
    return { success, message };
}

export default actions;