import { isAuthenticatedGuard } from '@/router/auth-guard';

export default [
   
        {
            path: '/imagenologia',
            name: 'Imaging',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/imaging/Imaging.vue'),
        },

        {
            path: '/imagenologia-crear',
            name: 'ImagingCreate',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/imaging/subpages/create/ImagingCreate.vue'),
        },

        {
            path: '/imagenologia-editar/:id',
            name: 'ImagingEdit',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/imaging/subpages/edit/ImagingEdit.vue'),
        },

        {
            path: '/imagenologia-ver/:id',
            name: 'ImagingView',
            beforeEnter: [ isAuthenticatedGuard ],
            component: () => import('@/views/imaging/subpages/view/ImagingView.vue'),
        },
]