import { ImagingState } from "@/interfaces/customs/IImaging";

function state(): ImagingState {
  return {
    dataPatient:{
        id: null,
        occupation: null,
        phone: null,
        secondary_phone: null,
        email: null,
        address: null
    },
    dataImaging:{
        procedure: null,
        date: null,
        status: null,
        observation: null,
        documents: []
    },
    dataMovement:{
      date: null,
      status: null,
      equipment: null,
      description: null,
      interpretation: null,
      document: []
    }
  };
}

export default state;
