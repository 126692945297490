// vuex
import { ActionTree } from "vuex";

import {
  centerRefListApi,
  specialitiesByCenterRefApi,
  doctorsByCenterSpecialtyApiV3,
  careCenterListApi,
  allRegimen,
  servicesTypeApi,
  meetsResponseApi,
  allEPS,
} from "@/apis/general/generalApi";
import { schedulesApiV3 } from "@/apis/schedulesApi";
import {
  doctorAvailabilitiesByMonthApi,
  doctorAvailabilitiesByDateApi,
  createScheduleApi,
} from "@/apis/availableApi";

// composables
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useTypes } from "@/composables/useTypes";
import { useAlertV2 } from "@/composables/useAlertV2";

// interfaces
import { GeneralStoreUI } from "./state";
import { StateInterface } from "../index";
import { IErrors } from "@/interfaces/customs/IShowErrors";
import {
  IDoctorAvailableDetailsV3,
  ITypeServices,
} from "@/interfaces/global/Availables";
import {
  ILoadDoctorOptions,
  IScheduleDay,
} from "@/interfaces/customs/ScheduleUI";
import { IPendingTelexpertiseV3 } from "@/interfaces/global/Telexpertise";
import { ISearchByPatient } from "@/interfaces/global/Patient";
import { IMeetResponse } from "@/interfaces/global/MeetResponse";
import {
  IQuasarSelectors,
  IQuasarSelectorsGender,
  ISelectorTypes,
} from "@/interfaces/customs/Type";

const { openMainLoader } = useMainLoader();
const { serviceType, responseMeetStatus } = useTypes();
const { showCatchError } = showErrorInTS();
const { showAlert } = useAlertV2();

const getDoctorList = (
  receivedDoctorList: IQuasarSelectors[],
  typeSelector: ISelectorTypes
) => {
  if (receivedDoctorList.length === 0) {
    return [];
  }

  if (typeSelector === "individual") {
    return receivedDoctorList;
  }

  return [{ label: "Todos los doctores", value: 0 }, ...receivedDoctorList];
};

const getServiceTypeList = (
  receivedServicesList: IQuasarSelectors[],
  typeSelector: ISelectorTypes
) => {
  if (receivedServicesList.length === 0) {
    return [];
  }

  if (typeSelector === "individual") {
    return receivedServicesList;
  }

  return [{ label: "Todos los servicios", value: 0 }, ...receivedServicesList];
};

const actions: ActionTree<GeneralStoreUI, StateInterface> = {
  async DispatchMeetsResponse({ commit }, { type, session, token }) {
    commit("setMeetStatus", "counter/standby");

    try {
      const loadInfoMeet = await meetsResponseApi().get(
        `?type=${type}&session=${session}&token=${token}`
      );

      const infoMeetDataRef: IMeetResponse = loadInfoMeet.data.data;

      const infoMeetData = {
        patientName: infoMeetDataRef.patient.full_name,
        serviceType: infoMeetDataRef.current_schedule.session_type.name,
        initialHour: infoMeetDataRef.current_schedule.scheduled_at.slice(-8),
        finalHour: infoMeetDataRef.current_schedule.schedule_final_hour,
        fullDate: infoMeetDataRef.current_schedule.scheduled_at,
        specialtyName: infoMeetDataRef.specialty.description,
        urlMeet: `${infoMeetDataRef.current_schedule.schedule_url}`,
        doctorName: infoMeetDataRef.doctor
          ? infoMeetDataRef.doctor.name
          : "Sin definir",
        meetStatus: infoMeetDataRef.current_schedule.status,
      };

      const meetStatus =
        infoMeetDataRef.doctor && infoMeetData.meetStatus !== "Realizada"
          ? "counter/active"
          : "counter/disable";
      commit("setMeetResponse", infoMeetData);
      commit("setMeetStatus", meetStatus);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;

      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      commit(
        "setMeetStatus",
        responseMeetStatus(String(showCatchError(errorParse))).type
      );
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // Loads
  async LoadServiceTypeOptions(
    _,
    {
      centerRefId,
      serviceType,
    }: { centerRefId: number; serviceType: ISelectorTypes }
  ) {
    const paramURL = centerRefId;
    try {
      const serviceTypeOptionsRef = await servicesTypeApi().get(
        `?reference_center=${paramURL}`
      );
      const serviceTypeOptions = serviceTypeOptionsRef.data.data.map(
        (serviceType: ITypeServices) => ({
          label: serviceType.name,
          value: serviceType.id,
        })
      );

      const receivedServicesList: IQuasarSelectors[] = serviceTypeOptions || [];

      const servicesListOptions = getServiceTypeList(
        receivedServicesList,
        serviceType
      );

      return { status: "success", servicesListOptions };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      throw new Error(String(showCatchError(errorParse)));
    }
  },

  async LoadCareCenterSelectorOptions({ commit }) {
    commit("setCareCenterSelectedLoader", true);
    try {
      const careCenterSelectorOptions = await careCenterListApi().get(
        `/2?status=active`
      );

      const careCenterList = careCenterSelectorOptions.data.data.filter(
        (centerRef: { referenceCenter: object | null }) =>
          centerRef.referenceCenter !== null
      );
      const setupCareCenters = [...careCenterList].map((careCenter) => ({
        label: careCenter?.name,
        value: careCenter?.id,
        centerRefId: careCenter.referenceCenter?.id,
        centerRefName: careCenter.referenceCenter?.name,
      }));

      commit("setCareCenterSelectorOptions", setupCareCenters);
      commit("setCareCenterSelectedLoader", false);

      const careCenterMessage = () => {
        if (setupCareCenters.length === 0 || !setupCareCenters) {
          showAlert("No hay centros de atención listados", "warning");
        }
      };
      careCenterMessage();
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setCareCenterSelectorOptions", []);
      commit("setCareCenterSelectedLoader", false);

      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // Loads
  async LoadCenterRefSelectorOptions({ commit }) {
    commit("setCenterRefSelectedLoader", true);

    try {
      const centerRefSelectorOptions = await centerRefListApi().get(
        `/1?status=active`
      );
      const centerRefList =
        centerRefSelectorOptions.data.data.map(
          (option: { name: string; id: number }) => ({
            label: option?.name,
            value: option?.id,
          })
        ) || [];

      const firstValue =
        centerRefList.length > 0 ? centerRefList[0] : undefined;
      commit("setCenterRefSelectorOptions", centerRefList);
      commit("setFirstCenterRefOption", firstValue);
      commit("setCenterRefSelectedLoader", false);

      const centerRefMessage = () => {
        if (centerRefList.length === 0 || !centerRefList) {
          showAlert("No hay centros de referencia listados", "warning");
        }
      };
      centerRefMessage();
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setCenterRefSelectorOptions", []);
      commit("setCenterRefSelectedLoader", false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async AllEPS({ commit }, { token }) {
    // loader
    commit("setEpsSelectorLoader", true);
    openMainLoader(true);

    try {
      const epsSelectorOptions = await allEPS.get(`/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const epsList = epsSelectorOptions.data.data;
      const epsMessage = epsSelectorOptions.data.message;

      const epsOptions = [...epsList].map((eps) => {
        return {
          value: eps.id,
          label: eps.name,
        };
      });

      // selectors - center
      commit("setEpsSelectorOpions", epsOptions);
      commit("setEpsSelectorLoader", false);
      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${epsMessage}!`, "success");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async RegimenEPS({ commit }, { token }) {
    // loader
    openMainLoader(true);
    commit("setRegimenSelectorLoader", true);

    try {
      const regimenSelectorOptions = await allRegimen.get(`/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const regimenList = regimenSelectorOptions.data.data;
      const regimenMessage = regimenSelectorOptions.data.message;

      const regimenOptions = [...regimenList].map((eps) => {
        return {
          value: eps.id,
          label: eps.name,
        };
      });

      // selectors - center
      commit("setRegimenSelectorOptions", regimenOptions);
      commit("setRegimenSelectorLoader", false);
      // loader
      openMainLoader(false);

      // alert
      showAlert(`¡${regimenMessage}!`, "success");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      openMainLoader(false);
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  async LoadSpecialitiesOptionsV2(
    _,
    {
      centerRefId,
      serviceType,
    }: { centerRefId: number; serviceType: ISelectorTypes }
  ) {
    const paramURL = `/${centerRefId}`;

    try {
      const specialityOptions = await specialitiesByCenterRefApi().get(
        `${paramURL}`
      );

      const specialityList = specialityOptions.data.data.map(
        (option: { description: string; id: number }) => ({
          label: option.description,
          value: option.id,
        })
      );

      const receivedSpecialtyList: IQuasarSelectors[] = specialityList || [];
      const getSpecialtiesListOptions = (
        receivedSpecialtyList: IQuasarSelectors[],
        serviceType: string
      ): IQuasarSelectors[] => {
        if (receivedSpecialtyList.length === 0) {
          return [];
        }
        if (serviceType === "individual") {
          return receivedSpecialtyList;
        }
        return [
          { label: "Todas las especialidades", value: 0 },
          ...receivedSpecialtyList,
        ];
      };

      const specialtiesListOptions: IQuasarSelectors[] =
        getSpecialtiesListOptions(receivedSpecialtyList, serviceType);

      return { status: "success", specialtiesListOptions };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      return { status: "error", message: String(showCatchError(errorParse)) };
    }
  },

  async LoadDoctorBySpecialityOptions(
    _,
    {
      centerRefId,
      serviceTypeId,
      specialityId,
      typeSelector,
    }: ILoadDoctorOptions
  ) {
    try {
      const typeId = !serviceTypeId ? "" : serviceTypeId;
      const bySpecialtyURL = `/${centerRefId}?specialty=${specialityId}&service=${typeId}`;
      const doctorListRef = await doctorsByCenterSpecialtyApiV3().get(
        `${bySpecialtyURL}`
      );
      const doctorsListOptions = doctorListRef.data.data.data.map(
        (option: IDoctorAvailableDetailsV3) => ({
          label: option.name,
          value: option.id,
          gender: option.details.gender,
        })
      );
      const receivedDoctorList: IQuasarSelectors[] = doctorsListOptions || [];

      const getDoctorList = (
        receivedDoctorList: IQuasarSelectors[],
        typeSelector: string
      ): IQuasarSelectors[] => {
        if (receivedDoctorList.length === 0) {
          return [];
        }
        if (typeSelector === "individual") {
          return receivedDoctorList;
        }
        return [
          { label: "Todos los doctores", value: 0 },
          ...receivedDoctorList,
        ];
      };

      const doctorList: IQuasarSelectors[] = getDoctorList(
        receivedDoctorList,
        typeSelector
      );

      return { status: "success", doctorList };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      return { status: "error", message: String(showCatchError(errorParse)) };
    }
  },

  // WORKING HERE !!!!
  async LoadSchedulePending(
    _,
    {
      centerRef,
      service,
      speciality,
      doctor,
    }: {
      centerRef: IQuasarSelectors;
      service: IQuasarSelectors;
      speciality: IQuasarSelectors;
      doctor: IQuasarSelectorsGender;
    }
  ) {
    try {
      const schedulePendingURL = `?reference_center=${centerRef.value}&type=${service.value}&specialty=${speciality.value}&user_id=${doctor.value}`;
      const scheduleAvailableRef = await schedulesApiV3().get(
        `v3/sessions${schedulePendingURL}`
      );

      const scheduleOptions: IPendingTelexpertiseV3[] = !scheduleAvailableRef
        ? []
        : scheduleAvailableRef.data.data;
      const scheduleList = scheduleOptions.map((option) => ({
        centerRefName: centerRef.label,
        centerRefId: centerRef.value,
        serviceName: service.label,
        serviceId: service.value,
        specialtyName: speciality.label,
        specialtyId: speciality.value,
        doctorName: doctor.label,
        doctorId: doctor.value,
        doctorGender: doctor.gender,
        careCenter: option.care_center.name,
        careCenterId: option.care_center.id,
        label: option.id,
        telexpertiseId: option.id,
        description: option.current_schedule.scheduled_at_formatted,
        priority: option.priority,
        patientId: option.patient.id,
        patientName: `${option.patient.name} ${option.patient.last_name}`,
        patientGender: option.patient.gender,
        patientDocument: option.patient.document,
        specialityName: option.specialty.description,
        specialityId: option.specialty.id,
        creatorName: option.current_schedule.creator
          ? option.current_schedule.creator.name
          : "Sin definir",
        creatorId: option.current_schedule.creator
          ? option.current_schedule.creator.id
          : -1,
        typeId: option.current_schedule.session_type.id,
        cups: option.cups ? option.cups.description : "No aplica",
      }));
      return { status: "success", scheduleList };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      return { status: "error", message: String(showCatchError(errorParse)) };
    }
  },

  async LoadingAvailableByMonth(
    _,
    { doctorId, selectMonth, selectYear, serviceId }
  ) {
    const composedUrl = `/${doctorId}/${selectMonth}/${selectYear}?type_id=${serviceId}`;
    try {
      const availableInCalendar = await doctorAvailabilitiesByMonthApi().get(
        composedUrl
      );

      const availableInCalendarList = availableInCalendar.data.data;
      const availableInCalendarMessage = availableInCalendar.data.message;
      const message =
        availableInCalendarList.length > 0
          ? availableInCalendarMessage
          : "Este centro no tiene especialidades asignadas";

      const alertType =
        availableInCalendarList.length > 0 ? "success" : "warning";

      return { status: alertType, availableInCalendarList, message };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      return { status: "error", message: String(showCatchError(errorParse)) };
    }
  },

  async LoadingAvailableByDay(_, { doctorId, fullYear, typeId }) {
    try {
      const availableByDay = await doctorAvailabilitiesByDateApi().get(
        `/${doctorId}/${fullYear}?type_id=${typeId}`
      );
      const availableInCalendarList: IScheduleDay[] = availableByDay.data.data;
 
      const availableByDayList =
        availableInCalendarList.length > 0 ? availableInCalendarList : [];

      const validateAvailables =
        availableInCalendarList.length > 0 &&
        availableInCalendarList.some((day) => day.status === "active");

      const availableInCalendarMessage =
        availableByDayList.length > 0 && validateAvailables
          ? availableByDay.data.message
          : "¡No hay disponibilidad!";
      const availableInCalendarStatus =
        availableByDayList.length > 0 && validateAvailables
          ? "success"
          : "warning";
      return {
        status: availableInCalendarStatus,
        byDay: availableByDayList,
        message: availableInCalendarMessage,
      };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      return {
        status: "error",
        byDay: [],
        message: String(showCatchError(errorParse)),
      };
    }
  },

  async CreateSchedule(
    _,
    {
      session_id,
      user_id,
      date,
      type,
    }: {
      session_id: number;
      user_id: number;
      date: string;
      type: number;
    }
  ) {

    try {
      const createSchedule = await createScheduleApi().post(`/v3`, {
        session_id,
        user_id,
        date,
        type,
      });



      const createScheduleMessage = createSchedule.data.message;

      return { status: "success", message: createScheduleMessage };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      return { status: "error", message: String(showCatchError(errorParse)) };
    }
  },

  async CancelAppointmentSchedule(
    _,
    {
      session_id,
      reason,
      type,
    }: {
      session_id: string;
      reason: string | undefined;
      type: number;
    }
  ) {

    try {
      const cancelSchedule = await createScheduleApi().post(`/v3/cancel`, {
        session_id,
        reason,
        type,
      });

      const cancelScheduleMessage = cancelSchedule.data.message;

      return { status: "success", message: cancelScheduleMessage };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      return { status: "error", message: String(showCatchError(errorParse)) };
    }
  },

  async RescheduleAppointmentSchedule(
    _,
    {
      session_id,
      reason,
      type,
    }: {
      session_id: number;
      reason: string;
      type: number;
    }
  ) {
    try {
      const rescheduleSchedule = await createScheduleApi().post(
        `/v3/reschedule`,
        {
          session_id,
          reason,
          type,
        }
      );

      const rescheduleScheduleMessage = rescheduleSchedule.data.message;

      return { status: "success", message: rescheduleScheduleMessage };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      return { status: "error", message: String(showCatchError(errorParse)) };
    }
  },

  //RescheduleAppointmentSchedule

  async LoadSearchByPatient(
    _,
    {
      centerRef,
      serviceRef,
      specialtyRef,
      doctorRef,
      currentYear,
      currentMonth,
      currentDay,
      documentId,
    }: {
      centerRef: IQuasarSelectors;
      serviceRef: IQuasarSelectors;
      specialtyRef: IQuasarSelectors;
      doctorRef: IQuasarSelectorsGender;
      currentYear: number;
      currentMonth: number;
      currentDay: number;
      documentId: number;
    }
  ) {
    try {
      const searchPatient = await createScheduleApi().get(
        `/v3/patient?document=${documentId}&reference_center=${centerRef.value}`
      );

      const patientInfoRef: ISearchByPatient = searchPatient.data.data;
      const patientInfo = {
        centerRefName: centerRef.label,
        centerRefId: centerRef.value,
        careCenter: patientInfoRef.care_center.name,
        serviceName: serviceRef.label,
        serviceId: serviceRef.value,
        specialtyName: specialtyRef.label,
        specialtyId: specialtyRef.value,
        doctorName: doctorRef.label,
        doctorId: doctorRef.value,
        doctorGender: doctorRef.gender,
        label: patientInfoRef.id,
        telexpertiseId: patientInfoRef.id,
        description: "Sin definir",
        priority: "Sin definir",
        patientId: patientInfoRef.user_id,
        patientName: `${patientInfoRef.name} ${patientInfoRef.last_name}`,
        patientGender: patientInfoRef.gender,
        patientDocument: patientInfoRef.document,
        specialityName: specialtyRef.label,
        specialityId: specialtyRef.value,
        creatorName: "Sin definir",
        creatorId: -1,
        typeId: serviceRef.value,
        cups: "No aplica",
        currentEPS: patientInfoRef.eps.name,
        age: patientInfoRef.age,
        phone: patientInfoRef.phone,
        email: patientInfoRef.email,

      };

      const message = searchPatient.data.message;
      return { status: "success", patientInfo, message };
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      const errorType =  String(showCatchError(errorParse)) === "El campo documento de paciente seleccionado no es válido." ? "warning" : "error";
      return { status: errorType, message: String(showCatchError(errorParse)) };
    }
  },
};

export default actions;

