import axios from "axios";

export const PatientsApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/patients`,
})

export const ImagingApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/telemedicine/imaging`,
})

export const ProceduresSearchApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/settings/procedures/search`,
})

export const DocumentsApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/documents/imaging`,
})